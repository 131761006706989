
import Kirbyimage from './kirbyimage.vue'
import KirbyLink from './kirbylink.vue'
export default {
    name: "solutionssetusapart",
    props:{
      content: Object
    },
    components:{
      KirbyLink, Kirbyimage
    }
}
